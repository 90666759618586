import { Injectable } from '@angular/core';
import { ValuesService } from '../../values/values.service';
import { environment } from '../../../../../src/environments/environment';
import { catchError, from, map, Subject, takeUntil } from 'rxjs';
import { MessageService } from './message.service';
import { OutletsStatusService } from './outlets.status.service';
import { QueryParamsService } from './query.params.service';
import { ScriptService } from './scripts.service';
import { UsefulService } from '../global/useful/useful.service';
import { UtilsCommonService } from '../../utils/utils-common.service';
import { CampaignParameters } from './AdobeParams.model';
import { Scripts } from './models/Scripts.model';
import { Router } from '@angular/router';
import { NavigationService } from '../../navigation/navigation.service';
import { ModalRoutelessService } from '../../components/ui/ui-modal-routeless/modal.routeless.service';
import { schemas } from '../../models/schemas';
import { tv4 } from 'tv4';
import { GroupTypes } from '../../models/subscriptions/Groups.model';

type GrouoptTypeAdobeMapping = {
    [key in GroupTypes]?: string
}

interface GrouoptTypeAdobeMappingInterface extends GrouoptTypeAdobeMapping {
    default: string
}

@Injectable({
    providedIn: 'root'
})

export class AdobeDataLayerService {

    _adobeAnalyticsProjectMapping = {
        'default': 'armor',
        'test': 'armor'
    };

    private readonly groupTypeToAdobePropertyMappring: GrouoptTypeAdobeMappingInterface = {
        [GroupTypes.VSB]: 'business',
        default: 'consumer'
    };

    omnitureDataState = {
        pageLoadStartedEventTriggered: false,
        pathSections: [],
        modalSections: [],
        path: null,
        modal: null
    };

    private readonly beaconSubject: Subject<void> = new Subject<void>();
    private finalComponentBeacon = false;
    private redirectBeacon = false;

    constructor(
        private readonly valuesService: ValuesService,
        private readonly queryParamsService: QueryParamsService,
        private readonly messageService: MessageService,
        private readonly outletsStatusService: OutletsStatusService,
        private readonly navigationService: NavigationService,
        private readonly scriptService: ScriptService,
        private readonly usefulService: UsefulService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly router: Router
    ) {
        this.beaconSubject.subscribe({
            next: () => {
                if (this.finalComponentBeacon && this.redirectBeacon) {
                    // send datalayer beacon
                    this.triggerAdobePageLoadEvents();
                    // reset flags
                    this.finalComponentBeacon = false;
                    this.redirectBeacon = false;
                } else {
                    return;
                }
            }
        })
    }

    checkGetState() {
        return  typeof window?.adobeDataLayer?.getState === 'function';
    }

    /**
     * Function to load Adobe script that sets custom events for Target
     */
    public loadApi() {
        return from(this.scriptService.loadScript(Scripts.Adobe))
        .pipe(
            map(
                (resp) => {
                    if (!resp['loaded']) {
                        throw 'Error on loading';
                    }

                    if (window.hasOwnProperty('adobe') && window['adobe'].hasOwnProperty('target')) {
                        document.addEventListener(window['adobe'].target.event.CONTENT_RENDERING_SUCCEEDED, (event) => {
                            if (event?.detail?.execution[0]?.pageLoad) {
                                this.messageService.sendMessage( this.valuesService.events.targetActivityLoaded, {});
                            }
                        });
                        document.addEventListener(window['adobe'].target.event.CONTENT_RENDERING_FAILED, (event) => {
                            window['targetExperimentsReady'] = true;
                            this.messageService.sendMessage( this.valuesService.events.targetActivityLoaded, {});
                        });
                        document.addEventListener(window['adobe'].target.event.CONTENT_RENDERING_NO_OFFERS, (event) => {
                            window['targetExperimentsReady'] = true;
                            this.messageService.sendMessage( this.valuesService.events.targetActivityLoaded, {});
                        });
                        // > DO NOT DELETE THIS EVENTS
                        /**
                        document.addEventListener(window['adobe'].target.event.CONTENT_RENDERING_REDIRECT, (event) => {
                            console.log('CONTENT_RENDERING_REDIRECT');
                        });
                        document.addEventListener(window['adobe'].target.event.CONTENT_RENDERING_START, (event) => {
                            console.log('CONTENT_RENDERING_START');
                        });
                        document.addEventListener(window['adobe'].target.event.REQUEST_START, (event) => {
                            console.log('REQUEST_START');
                        });
                        document.addEventListener(window['adobe'].target.event.REQUEST_SUCCEEDED, (event) => {
                            console.log('REQUEST_SUCCEEDED');
                        });
                        document.addEventListener(window['adobe'].target.event.REQUEST_FAILED, (event) => {
                            this.messageService.sendMessage( this.valuesService.events.targetActivityLoaded, {});
                        });
                         */
                    } else {
                        this.messageService.sendMessage( this.valuesService.events.targetActivityLoaded, {});
                    }

                }
            ),
            catchError(
                (err) => {
                    this.messageService.sendMessage( this.valuesService.events.targetActivityLoaded, {});
                    throw err;
                }
            )
        )
    };

    private triggerAdobePageLoadEvents() {
        window.adobeDataLayer.push({
            'event': 'page load started'
        });
        //\ Moved from User Info Service
        window.adobeDataLayer.push({
            'event': 'user detected'
        });
        window.adobeDataLayer.push({
            'event': 'page loaded'
        });
    }

    private displayAdobeConsoleLog() {
        if (!this.checkGetState()) {
            return;
        }
        console.log('💚 Adobe Page/View Loaded 💚', window.adobeDataLayer.getState());
        console.log('💙 Adobe PageInfoName 💙', window.adobeDataLayer.getState('page.info.name'));
    }

    /**
     * Gets the section from an URL: http://ceva#sectiunea1
     * @param url Url
     * @returns Section ex.: 'sectiunea1'
     */
    private getURLsSection(url) {
        return url.split('#')[1];
    }

    /**
     * Gets the url without the section: http://ceva#sectiunea1
     * @param url Url
     * @returns Url without section ex.: 'http://ceva'
     */
    private getURLNoSection(url) {
        return url.split('#')[0];
    }

    /**
     * Recomposes the url
     * @param section Section ex.: 'sectiunea1'
     * @param url Url without section ex.: 'http://ceva'
     * @returns Url with section ex.: 'http://ceva#sectiunea1'
     */
    private getFinalUrlWithSection(section, url) {
        if (section) {
            return url.concat('#', section);
        }
        return url;
    }

     /**
    * This method adds an unique id for external links to know where the user comes from
    * This id is put as value to the 'adobe_mc' query parameter
    * So the resulting url will be: <url>?adobe_mc=<id>
    * @param {string} link Url without ID
    * @returns {string} New url with the ID added
    */
    public addOmnitureVisitor(link) {
        if (!link) {
            return link;
        }
        const section = this.getURLsSection(link);
        link = this.getURLNoSection(link);
        let visitor;
        if (typeof window['Visitor'] !== 'undefined') {
            visitor = window['Visitor'].getInstance(this.valuesService.adobeTrackingID, {
                trackingServer: this.valuesService.trackingHttpServerAdobe, // Same as s.trackingServer
                trackingServerSecure: this.valuesService.trackingHttpsServerAdobe, // Same as s.trackingServerSecure
            });
        }

        if (typeof visitor === 'object') {
            link = visitor.appendVisitorIDsTo(link);
        }
        return this.getFinalUrlWithSection(section, link);
    };

    /**
    * This method adds ref param to external bd links
    * @param {string} link Url
    * @param {boolean} encodeURL encode ref param
    * @returns {string} New url with the ref param added
    * ! De vazut daca trebuie contopit cu addOmnitureVisitor
    */
    public addOmnitureRef(link: string, encodeURL?: boolean) {
        if (!link) {
            return link;
        }
        const section = this.getURLsSection(link);
        link = this.getURLNoSection(link);
        let currentPath = this.usefulService.cleanUpLocationHrefForRef();
        if (encodeURL) {
            currentPath = encodeURIComponent(currentPath);
        }
        const params = link.split('?')[1];
        const objectParams = this.usefulService.queryStringToJson(params);

        if (!this.utilsCommonService.isEmptyObject(objectParams)) {
            link = `${link}&ref=${currentPath}`;
        } else {
            link = `${link}?ref=${currentPath}`;
        }
        return this.getFinalUrlWithSection(section, link);
    }

    /**
    * This method adds 'ref' and 'adobe_mc' param to external links
    * @param {string} link Url
    * @param {boolean} encodeURL encode ref Url
    * @returns {string} New url with ref and adobe_mc param added
    */
    public addRefAndVisitor(link: string, encodeURL = false) {
        if (!link) {
            return link;
        }
        const section = this.getURLsSection(link);
        link = this.getURLNoSection(link);
        link = this.addOmnitureRef(link, encodeURL);
        link = this.addOmnitureVisitor(link);
        return this.getFinalUrlWithSection(section, link);
    }

    private _joinArrayElements(array) {
        let joinedArray = '';
        for (const element of array) {
            if (element !== null && element !== undefined) {
                joinedArray = joinedArray.concat(':', element);
            }
        }
        return joinedArray;
    }

    /**
     * Returns a link with a 'cid/icid' parameter. Useful to track campaigns links from central (ex. install campaigns)
     * Example of return: <link>?cid=<mediaId>|<audience>|<platform>|<campaignName>|<domain>
     * @param link Link without 'cid/icid' parameter
     * @param internal If true, parameter name is 'icid', otherwise is 'cid'
     * @param mediaId Media item name ex.: button
     * @param campaignName Campaign name ex.: buy-parental
     * @param domain Domain. Optional because in central was no need for it for all this time
     * @returns Link with 'cid/icid'
     */
    public addCidOrIcid(link: string, params: CampaignParameters) {
        if (!link) {
            return link;
        }
        const section = this.getURLsSection(link);
        link = this.getURLNoSection(link);
        const audience = 'c';
        const platform = 'central';
        const parameterName = params.internal ? this.valuesService.queryParams.icid : this.valuesService.queryParams.cid;

        let cidIcid = parameterName.concat('=', params.mediaId, '|', audience, '|', platform, '|', params.campaignName);
        cidIcid = params.domain ? cidIcid.concat('|', params.domain) : cidIcid;

        // >> IMPORTANT 'link' should not contain more than one '?' character
        if (link.indexOf('?') !== -1) {
            link = link.concat('&', cidIcid);
        } else {
            link = link.concat('?', cidIcid);
        }
        return this.getFinalUrlWithSection(section, link);
    }

    public addCidOrIcidRefAndVisitor(link: string, params: CampaignParameters) {
        if (!link) {
            return link;
        }
        const section = this.getURLsSection(link);
        link = this.getURLNoSection(link);
        link = this.addCidOrIcid(link, params);
        link = this.addOmnitureRef(link);
        link = this.addOmnitureVisitor(link);
        return this.getFinalUrlWithSection(section, link);
    }

    /**
     * Function that adds 'utm_source' asa a query param for a link in order to help monitoring
     * @param link The destination link
     * @param source The vale of the param
     * @returns The link with the added param
     */
    public addUtmSource(link, source) {
        if (!link) {
            return link;
        }
        const section = this.getURLsSection(link);
        link = this.getURLNoSection(link);
        const utmSourceParam = 'utm_source';
        if (link.indexOf('?') > -1) {
            link =  link.concat('&', utmSourceParam, '=', source);
        } else {
            link = link.concat('?', utmSourceParam, '=', source);
        }
        return this.getFinalUrlWithSection(section, link);
    }

    /**
     * Pushes info about the page name nane and section. Changes local state and dataLayerState.
     */
    private generatePageInfoName(): void {
        const _computedName = this.computePageInfoName();
        const _section = this.computeProject();
        const _name = _section.concat(_computedName);

        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'name': _name,
                    'section': _section
                }
            }
        });
    }

    /**
     * Adds page sections to the page info. Changes local state and dataLayerState.
     */
    private generatePageInfoSections(): void {
        const _computedName = this.computePageInfoName();
        // Reset sections
        this.resetAdobeSections();
        this.addAdobeSections(_computedName)
    }

    /**
     * Function that adds sections to page and sections to modal (if modal exists)
     * @returns {string} A string formed like this 'page:pageSections:modal:modalSections'
     */
    private computePageInfoName(): string {
        if (!this.omnitureDataState.path) {
            return;
        }

        let _computedPathWithSections = this.computePathForPageInfoName(this.omnitureDataState.path);
        if (this.omnitureDataState.pathSections.length && !this.modalRoutelessService.getIsModalOpened()) {
            _computedPathWithSections = _computedPathWithSections.concat(this._joinArrayElements(this.omnitureDataState.pathSections));
        }

        if (!this.omnitureDataState.modal) {
            return _computedPathWithSections;
        }

        let _computedModalNameWithSections = this.omnitureDataState.modal;
        if (this.omnitureDataState.modalSections.length) {
            _computedModalNameWithSections = _computedModalNameWithSections.concat(this._joinArrayElements(this.omnitureDataState.modalSections));
        }

        return _computedPathWithSections.concat(':', _computedModalNameWithSections);
    }

    /**
     * Removes every section from adobe omniture array
     * @returns void
     */
    private resetAdobeSections() {
        if (!this.checkGetState()) {
            return;
        }

        const _pageInfo = window.adobeDataLayer.getState('page.info');
        for (const key in _pageInfo) {
            if (key.indexOf('Section') !== -1) {
                window.adobeDataLayer.push({
                    'page': {
                        'info': {
                            [key]: null
                        }
                    }
                });
            }
        }
    }

    /**
     * Function that adds in window.adobeDataLayer objects containing page.info = {Section: <section>} or
     * page.info = {SubSection: <subSection>}, repeating 'Sub' as many times as necessary
     * @param _concatenatedSections A string formed like this 'section:subSection:subSubSection'
     */
    private addAdobeSections(_concatenatedSections:string) {
        if (_concatenatedSections === undefined || _concatenatedSections === null) {
            return;
        }

        let _sections = _concatenatedSections.split(':').slice(1);
        let _repeating_text = 'Sub';
        let _propertyText = '';

        let sectionCounter = 0;
        for (const section of _sections) {
            _propertyText = _repeating_text.repeat(sectionCounter + 1).concat('Section');
            _propertyText = _propertyText.charAt(0).toLowerCase().concat(_propertyText.slice(1));

            window.adobeDataLayer.push({
                'page': {
                    'info': {
                        [_propertyText]: section
                    }
                }
            });
            sectionCounter++;
        }
    }

    /**
     * Method that adds path url to adobe object
     * @param {string} the url to be added
     * @returns {void}
     */
    public setUrl(url: string): void {
        this.omnitureDataState.path = url;

        this.generatePageInfoName();
        this.generatePageInfoSections();
    }

    /**
     * Method that adds modal name to adobe object
     * @param {string} the modal name to be added
     * @returns {void}
     */
    public setModal(modal: string): void {
        this.omnitureDataState.modal = modal;

        this.generatePageInfoName();
        this.generatePageInfoSections();
    }

    public removeModal() {
        this.omnitureDataState.modal = null;
    }

    /**
     * Method that adds a section in omnitureDataState.pathSections
     * @param {string} section Name of section
     * @param {number | undefined | null} level Position in array
     * @param {boolean} triggerPageLoad Generete page load event
     * @returns {nothing}
     */
    public setPageSection(section: string, level?: number, triggerPageLoad?: boolean): void {
        if (level === undefined || level === null) {
            this.omnitureDataState.pathSections.push(section);
        } else {
            this.omnitureDataState.pathSections[level] = section;
        }
        this.generatePageInfoName();
        this.generatePageInfoSections();
        if (triggerPageLoad) {
            this.triggerPageLoad();
        }
    }

    /**
     * Method that removes a section from omnitureDataState.pathSections
     * @param {number} level Position in array
     * @param {boolean} triggerPageLoad Generete page load event
     * @returns {nothing}
     */
    public removePageSection(level: number, triggerPageLoad?: boolean): void {
        this.omnitureDataState.pathSections.splice(level, 1);
        this.generatePageInfoName();
        this.generatePageInfoSections();
        if (triggerPageLoad) {
            this.triggerPageLoad();
        }
    }

    /**
     * Method that adds a section in omnitureDataState.modalSections
     * @param {string} section Name of section
     * @param {number | undefined | null} level Position in array
     * @returns {nothing}
     */
    public setModalSection(section: string, level?: number, triggerPageLoad?: boolean): void {
        if (level === undefined || level === null) {
            this.omnitureDataState.modalSections.push(section);
        } else {
            this.omnitureDataState.modalSections[level] = section;
        }

        this.generatePageInfoName();
        this.generatePageInfoSections();
        if (triggerPageLoad) {
            this.triggerPageLoad();
        }
    }

    /**
     * Method that removes a section from omnitureDataState.modalSections
     * @param {number} level Position in array
     * @param {boolean} triggerPageLoad Generete page load event
     * @returns {nothing}
     */
    public removeModalSection(level: number, triggerPageLoad?: boolean): void {
        this.omnitureDataState.modalSections.splice(level, 1);
        this.generatePageInfoName();
        this.generatePageInfoSections();
        if (triggerPageLoad) {
            this.triggerPageLoad();
        }
    }

    // Compute project primary and secondary for Adobe
    private computeProject() {
        let project = null;
        if (environment.production) {
            if (this._adobeAnalyticsProjectMapping.hasOwnProperty(location.host)) {
                project = this._adobeAnalyticsProjectMapping[location.host];
            } else {
                project = this._adobeAnalyticsProjectMapping['default'];
            }
        } else {
            project = this._adobeAnalyticsProjectMapping['test'];
        }
        return project;
    }

    // Compute os version for Adobe
    private computeOs() {
        const userAgent = navigator.userAgent;
        let os;
        const clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Linux', r: /(Linux|X11)/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
        ];

        for (const clientString of clientStrings) {
            if (clientString.r.test(userAgent)) {
                os = clientString.s;
                break;
            }
        }

        return os;
    }

    private computeTime() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const d = new Date();
        const hour = d.getHours();
        const minutes = d.getMinutes();
        let minutesString = '';

        if (minutes < 10) {
            minutesString = '0'.concat(minutes.toString());
        } else {
            minutesString = minutes.toString();
        }

        const day = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        let time = `${hour}:${minutesString}|${hour}:00-${hour}:59|${days[d.getDay()]}|gmt`;
        if (d.getTimezoneOffset()) {
            const gmt = (d.getTimezoneOffset() / 60) * -1;
            if (gmt > 0) {
                time = time.concat('+', gmt.toString());
            } else {
                time = time.concat(gmt.toString());
            }
        }
        return {
            time,
            date: `${day}/${month}/${year}`
        }
    }

    public setRedirectStatus(value) {
        this.redirectBeacon = value;
        this.finalComponentBeacon = false;
        this.beaconSubject.next();
    }

    /**
     * Retuns path name for page.info.name atribute (ex: devices:details)
     * @param {string} path URL from browser
     * @returns {string} new path format
     */
    private computePathForPageInfoName(path: string): string {
        let _pathWithoutQueryParams = path.split('?')[0];
        const _formatedPath = this.removeIdsFromPath(_pathWithoutQueryParams);

        const bracketPosition = _formatedPath.indexOf('(');
        if (bracketPosition !== -1) {
            const mainPath = _formatedPath.slice(0, bracketPosition);
            return mainPath.replace(new RegExp('/', 'g'), ':');
        } else {
            return _formatedPath.replace(new RegExp('/', 'g'), ':');
        }
    }

    /**
     * Removes id from path
        - article ids (from support) [articles ids from privacy should remain]
        - support ids
        - tokens
     * @param {string} path URL from browser
     * @returns {string} clean path
     */
    private removeIdsFromPath(path: string): string {
        // We should take out all ids from url ( exception parental )
        const _pathSegments = path.split('/');
        const validPaths = new Set(['404', '500']);
        let segmentCounter = 0;

        for (const segment of _pathSegments) {
            const isValidToken = tv4.validate(segment, schemas.schemaToken);
            const isValidPath = validPaths.has(segment);
            const isNumber = !isNaN(parseInt(segment, 10)); //folosit pt crc-uri
            const isPrivacyArticlePath = _pathSegments?.[segmentCounter - 2] === this.valuesService.centralPaths.privacy.education.id && _pathSegments?.[segmentCounter - 1] === this.valuesService.centralPaths.privacy.education.article.id;
            const isSupportArticlePath = _pathSegments?.[segmentCounter - 2] === this.valuesService.centralPaths.support.id && _pathSegments?.[segmentCounter - 1] === this.valuesService.centralPaths.support.article.id;

            if (isValidToken || (isNumber && !isValidPath && !isPrivacyArticlePath) || isSupportArticlePath) {
                path = path.replace(`/${segment}`, '');
            }

            segmentCounter++;
        }

        return path;
    }

    // Operating system - start of the application (asap)
    public setOs() {
        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'sysEnv': this.computeOs()
                }
            }
        });
    }

    // Language - when language for user is known; used in language service.
    public setLang(lang) {
        if (lang.length !== 2) {
            console.warn('Invalid params for setLang');
            return;
        } else {
            lang = lang.toLowerCase();
        }

        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'language': lang
                }
            }
        });
    }

    public setPageAttributes(url?) {
        let attributes = {
            time: this.computeTime().time,
            date: this.computeTime().date,
            domain: 'bitdefender.com',
            domainPeriod: 2
        };

        window.adobeDataLayer.push({
            'page': {
                'attributes': attributes
            }
        });
    };

    /**
    * This method adds extra params to digitalData related to auto-renewal
    * @param {string} subId hash subscription id form Central url
    * @param {string} arStatus status enabled/disabled
    * @returns {nothing} creates extra params to digitalData object
    */
     setRenewalSubscriptionInfo = (subId, arStatus) => {
        window.adobeDataLayer.push({
            'user': {
                'subscription': {
                    ID: subId,
                    arStatus: arStatus
                }
            }
        });
    }

    public setUser(userInfo) {
        window.adobeDataLayer.push({
            'user': {
                'ID': userInfo.fingerprint
            }
        });
    }

    /**
     * Sets the user context type in adobe data layer
     * @param {GroupTypes} groupType The user's context type
     */
    public setUserContext(groupType: GroupTypes) {
        const contextType = this.groupTypeToAdobePropertyMappring?.[groupType] ?? this.groupTypeToAdobePropertyMappring.default;
        window.adobeDataLayer.push({
            'user': {
                'userRole': contextType
            }
        });
    }

    public setUserLoggedIn(value) {
        window.adobeDataLayer.push({
            'user': {
                'loggedin': value
            }
        });
    }

    public setServerName() {
        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'serverName': location.host
                }
            }
        });
    }

    public setTrackingId(params) {
        const _trackingId = this.getTrackingId(params);
        if (_trackingId !== '') {
            window.adobeDataLayer.push({
                'page': {
                    'attributes': {
                        'trackingID': _trackingId
                    }
                }
            });
        }
    }

    /**
     * Function that adds internal campaign id ( icid ) to adobe data layer
     * @param {string} icid Icid param that comes through url
     * @returns {void}
     */
    public setInternalCampaignId(icid) {
        if (icid) {
            window.adobeDataLayer.push({
                page: {
                    attributes: {
                        internalPromotionID: icid
                    }
                }
            });
        }
    }

    private getTrackingId(params) {
        let trackingID = '';
        for (const param in params) {
            switch (param) {
                case 'et_cid':
                    if (params.et_cid === 'emm') {
                        trackingID = 'emm';
                    }
                    break;
                case 'ipm_id':
                    trackingID = 'ipm';
                    break;
                case 'rp_rid':
                case 'rp_mid':
                    trackingID = 'emm';
                    break;
                case 'pid':
                    const decodedPid = decodeURI(params.pid);
                    if (decodedPid === 'EMM_') {
                        trackingID = 'emm';
                    }

                    if (decodedPid === 'IPM_' || decodedPid === 'IMM_' || decodedPid === 'splash_' || decodedPid === 'IPU_') {
                        trackingID = 'ipm';
                    }

                    if (decodedPid === 'bdaffc') {
                        trackingID = 'aff';
                    }
                    break;
                case 'od_id':
                    trackingID = 'od';
                    break;
                case 'sem_region':
                    trackingID = 'sem';
                    break;
                case 'bdaffc':
                case 'lc_aid':
                case 'lc_cid':
                case 'AFFILIATE':
                case 'awc':
                    trackingID = 'aff';
                    break;
                case 'sm_id':
                    trackingID = 'sm';
                    break;
                case this.valuesService.queryParams.cid:
                    trackingID = decodeURI(params[param]);
                    break;
            }
        }

        return trackingID;
    };

    /**
    * This method adds extra params to digitalData related to device details
    * @param {string} details contains device details: deviceOS, appIds, status, hasProtection, hasVpn, hasParental
    * @returns {nothing} creates extra params to digitalData object
    */
    public setDeviceDetailsInfo = (details) => {
        window.adobeDataLayer.push({
            'user': {
                'device': `${details.deviceOS}:${details.appIds}:${details.status}:${details.hasProtection}:${details.hasVpn}:${details.hasParental}`
            }
        });
    }

    private _mainPageInfoName = '';

    /**
     * Function that computes the name and section for current screen, when the user opens or closes a modal
     * @param url Url after it has been sanitized (sanitization is needed for parental, in dashbaord, for child index)
     * @param modal String(name of the modal) or null (if no modal).
     */
    public setPageInfo(url?:string, modal?:string) {
        const _path = this.computePathForPageInfoName(url);
        let _modalPath = '';
        if (modal) {
            _modalPath = `:${modal}`;
        }
        let _computedName = _path.concat(_modalPath);
        // when the modal closes, it adds the sections that are still there, because the idea is that those sections belong to the page
        // that opened the modal and therefore after closing it, user lands on that same page
        if (this.omnitureDataState.pathSections.length && _modalPath === '' && !this.modalRoutelessService.getIsModalOpened()) {
            _computedName = _computedName.concat(this._joinArrayElements(this.omnitureDataState.pathSections));
        }

        const _section = this.computeProject();
        const _name = _section.concat(_computedName);

        this._mainPageInfoName = _name;
        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'name': _name,
                    'section': _section
                }
            }
        });

        // Reset sections
        this.resetAdobeSections();
        this.addAdobeSections(_computedName);
    }

    /**
     * It is mostly if not all the time used wrong. Should be replaced with this.setSection()
     * @param section String
     */
    public setViewPageInfo(section) {
        let pageInfoName = this._mainPageInfoName;
        if (section) {
            pageInfoName = `${pageInfoName}:${section}`;
        } else {
            pageInfoName = `${pageInfoName}`;
        }

        // Reset sections
        this.resetAdobeSections();
        this.addAdobeSections(pageInfoName);
        this.triggerAdobePageLoadEvents();
        this.displayAdobeConsoleLog();
    }

    public setRecommendationCardScenarioEvent = (name) => {
        window.adobeDataLayer.push({
            'interaction': {
                'view': {
                    name: name
                }
            }
        });
    }

    private _clearOneTimeEvents() {
        window.adobeDataLayer.push({
            'user': {
                'subscription': {
                    'bundleID': null,
                    'type': null,
                    'error': null
                }
            }
        });
    }

    /**
     * Function that triggers click event
     * @param event {string} Name of the click event to be sent
     */
    public triggerClickEvent (event) {
        window.adobeDataLayer = window.adobeDataLayer || [];
        window.adobeDataLayer.push({
            'event': 'click',
            'asset': event
        });
        this._clearOneTimeEvents();
    }

    /**
     * Function that triggers user detected event
     * It's set on profiles service when we obtain user information
     */
    public triggerUserDetected() {
        window.adobeDataLayer.push({
            'event': 'user detected'
        });
    }

    private _statusListener;
    public triggerPageLoad() {

        const _stopStatusListener$: Subject<boolean> = new Subject<boolean>();
        const _sendAdobeEvent = () => {
            if(!this.navigationService.getIsNavigationInProgress()) {
                _stopStatusListener$.next(true);
                _stopStatusListener$.complete();

                this.triggerAdobePageLoadEvents();
                this.displayAdobeConsoleLog();

                // ! This should reset what has to be reset without affectig previous page loaded event
                this.resetActivationBundleInfo();
            }
        };

        if (!this._statusListener || (this._statusListener && this._statusListener.closed)) {
            this._statusListener = this.outletsStatusService.statusBroadcaster
            .pipe(takeUntil(_stopStatusListener$))
            .subscribe({
                next: (res) => {
                    if (res) {
                        _sendAdobeEvent();
                    }
                }
            });
        }
    }

    //> Referring and Destination URLs work together
    //#region
    private _firstTime = true;
    private _refferingSetOnNavigation = false;
    private _currentLocation = '';

    /**
     *  Set Method for referring url
     */
    public setReferringUrl() {
        const _ref = this.queryParamsService.get(this.valuesService.queryParams.ref);
        const _adobe_mc_ref = this.queryParamsService.get(this.valuesService.queryParams.adobeMcRef);

        let referringUrl = '';

        if (this._refferingSetOnNavigation) {
            return;
        }

        if (this._firstTime) {
            if (_adobe_mc_ref) {
                referringUrl = _adobe_mc_ref;
            } else if (_ref) {
                referringUrl = _ref;
            } else {
                referringUrl = document.referrer.split('?')[0];
                if (referringUrl === '') {
                    referringUrl = this._currentLocation;
                }
            }
            this._firstTime = false;
            this.queryParamsService.remove(this.valuesService.queryParams.adobeMcRef);
            this.queryParamsService.remove(this.valuesService.queryParams.ref);
        } else {
            referringUrl = this._currentLocation;
        }

        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'referringURL': referringUrl
                }
            }
        });
        this._refferingSetOnNavigation = true;
    }

    /**
     *  Set Method for destination url
     */
    public setDestinationUrl(url) {
        this._refferingSetOnNavigation = false;
        this._currentLocation = location.protocol.concat('//', location.host, url);

        window.adobeDataLayer.push({
            'page': {
                'info': {
                    'destinationURL': this._currentLocation
                }
            }
        });
    }

    /**
     *  Set Method for Video Url in video-box-modal
     */
    public setVideoUrl(video) {
        window.adobeDataLayer.push({
            'user': {
                'interactions': {
                    'videoUrl': video
                }
            }
        });
    }

    /**
     *  Set Method for Search article for ui search filed
     */
    public setSearchInfo(path, query) {
        window.adobeDataLayer.push({
            'user': {
                'interactions': {
                    'searchPage': path,
                    'searchQuery': query
                }
            }
        });
    }

     /**
     *  Set Method for Search article url for support article
     */
     public setSearchArticle(url) {
        window.adobeDataLayer.push({
            'user': {
                'interactions': {
                    'articleUrl': url
                }
            }
        });
    }
    //#endregion

    /**
     * Set method for vsb member removal answers
     * @param {string} answers 'answer1|answer2'
     * @returns {nothing} creates extra params to digitalData object
     */
    public setVsbMemberRemovalAnswers(answers: string): void {
        window.adobeDataLayer.push({
            'user': {
                'action': {
                    'details': answers
                }
            }
        });
    }

    /**
    * This method adds extra params to digitalData related to bundle activation
    * @param {string} bundleId bundle id of the bundle trial
    * @param {string} type bundle type, ex: trial, nfr, end_user
    * @returns {nothing} creates extra params to digitalData object
    */
    public setActivateBundleInfo = (bundleId, type) => {
        window.adobeDataLayer.push({
            'user': {
                'subscription': {
                    'bundleID': bundleId,
                    'type': type,
                    'error': null
                }
            }
        });
    }

    /**
    * This method adds extra params to digitalData related to bundle activation
    * @param {string} bundleId bundle id of the bundle trial
    * @param {string} type bundle type, ex: trial, nfr, end_user
    * @returns {nothing} creates extra params to digitalData object
    */
    public setErrorForBundleActivation = (error) => {
        window.adobeDataLayer.push({
            'user': {
                'subscription': {
                    'bundleID': null,
                    'type': null,
                    'error': error
                }
            }
        });
    }

    /**
    * Reset Method for vsb member removal answers 
    */
    public resetVsbMemberRemovalAnswers(): void {
        window.adobeDataLayer.push({
            'user': {
                'action': null
            }
        });
    }

    /**
     *  Reset Method for activation bundle info from data layer
     *  Reset on page change and when it's already set.
     */
    resetActivationBundleInfo() {
        window.adobeDataLayer.push({
            'user': {
                'subscription': null
            }
        });
    }

    /**
     *  Reset Method for dashboard cards Interaction Values from data layer
     *  Reset on page change and when it's already set.
     */
    public resetInteractionValues() {
        if (this.checkGetState() && window.adobeDataLayer.getState('interaction')) {
            window.adobeDataLayer.push({
                'interaction': null
            });
        }
    }

     /**
     *  Reset Method for dashboard cards Interaction Values from data layer
     *  Reset on page change and when it's already set.
     */
     public resetUserInteractions() {
        window.adobeDataLayer.push({
            'user': {
                'interactions': null
            }
        });
    }

    /**
     *  Reset Method for tracking ID from data layer
     *  Reset on page change and when it's already set.
     */
    public resetTrackingID() {
        this.queryParamsService.remove([
            'et_cid',
            'ipm_id',
            'rp_rid',
            'rp_mid',
            'pid',
            'od_id',
            'sem_region',
            'bdaffc',
            'lc_aid',
            'lc_cid',
            'AFFILIATE',
            'awc',
            'sm_id',
            this.valuesService.queryParams.cid
        ]);

        window.adobeDataLayer.push({
            'page': {
                'attributes': {
                    'trackingID': null
                }
            }
        });
    }

    /**
     *  Reset Method for interaction dashboard card from data layer
     *  Reset on page change and when it's already set.
     */
    removeRecommendationCardScenarioEvent() {
        window.adobeDataLayer.push({
            'interaction': null
        });
    }

    /**
     *  Reset Method for user device from data layer
     *  Reset on page change and when it's already set.
     */
    public resetUserDeviceValues() {
        window.adobeDataLayer.push({
            'user': {
                'device': null
            }
        });
    }

    /**
     *  Reset Method for path sections from state management
     *  Reset on page change and when it's already set.
     */
    public resetPathSections(): void {
        this.omnitureDataState.pathSections = [];
    }

    /**
     *  Reset Method for modal sections from state management
     *  Reset on modal close.
     */
    public resetModalSections(): void {
        this.omnitureDataState.modalSections = [];
    }
}